<template>
  <div>
    <b-col v-show="stdinfo" md="12">
      <b-card>
        <b-tabs>
    
          <b-tab active>
            <template #title>
              <feather-icon icon="HomeIcon" />
              <span>معلومات الشخصية للطالب </span>
            </template>
            <b-row class="mb-2">
              <b-col md="4">
                <b-form-input
                  v-model="stdinfodetails.name"
                  placeholder="اسم الطالب"
                />
          </b-col>
          <b-col  md="4">
            <b-form-group>
              <v-select
                v-model="stdinfodetails.country"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="country_arNationality"
                :options="search.countries"
                placeholder="الجنسية"
              />
            </b-form-group>
          </b-col>
          <b-col  md="4">
            <b-form-group>
              <v-select
                v-model="stdinfodetails.city"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="search.cities"
                placeholder="المحافظة"
              />
            </b-form-group>
          </b-col>
          <b-col  md="4">
            <b-form-group>
              <v-select
                v-model="stdinfodetails.department"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="search.departments"
                placeholder="القسم العلمي"
              />
            </b-form-group>
          </b-col>
          <b-col  md="4">
            <b-form-group>
              <v-select
                v-model="stdinfodetails.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="ar_title"
                :options="search.types"
                placeholder="نوع الدراسة"
              />
            </b-form-group>
          </b-col>
          <b-col  md="4">
            <b-form-group>
              <v-select
                v-model="stdinfodetails.gender"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="ar_title"
                :options="search.genders"
                placeholder="الجنس"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-input
              v-model="stdinfodetails.birthday"
              placeholder="التولد"
            />
      </b-col>
          <b-col  md="4">
            <b-form-group>
              <v-select
                v-model="stdinfodetails.branch"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="search.branchs"
                placeholder="الفرع العلمي"
              />
            </b-form-group>
          </b-col>
          <b-col  md="4">
            <b-form-group>
              <v-select
                v-model="stdinfodetails.graduationyear"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="year"
                :options="search.years"
                placeholder="سنة التخرج"
              />
            </b-form-group>
          </b-col>
          
          <b-col md="4">
            <b-form-input
              v-model="stdinfodetails.average"
              placeholder="المعدل"
            />
      </b-col>
      <b-col  md="4">
        <b-form-group>
          <v-select
            v-model="stdinfodetails.accepttype"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="search.accepttypes"
            placeholder="نوع النافذة"
          />
        </b-form-group>
      </b-col>
      <b-col  md="4">
        <b-form-group>
          <v-select
            v-model="stdinfodetails.window"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="search.windows"
            placeholder="رقم النافذة"
          />
        </b-form-group>
      </b-col>
          <b-col  md="4">
            <b-form-group>
              <b-form-textarea
                  id="input-invalid1"
                  placeholder="الملاحظات"
                  v-model="stdinfodetails.note"
                    rows="3"
                />
            </b-form-group>
          </b-col>
            </b-row>
            <div class="demo-inline-spacing">
              <b-button
            @click="stdInfo(stdinfodetails.stdinf_id)"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
            >
            <feather-icon
            icon="Minimize2Icon"
            class="mr-50"
            />
              <span class="align-middle">اغلاق</span> 
            </b-button>
            <b-button
            @click="uNM()"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
            >
            <feather-icon
            icon="ArchiveIcon"
            class="mr-50"
            />
              <span class="align-middle">تعديل المعلومات</span> 
            </b-button>
            </div>
          </b-tab>
          <b-tab >
            <template #title>
              <feather-icon icon="HomeIcon" />
              <span>السيرة الدراسية </span>
              
            </template>
            <app-timeline>
              <app-timeline-item 
              v-for="status in statuses" 
              :key="status.id"
              icon="UserIcon">
                <div
                  class="
                    d-flex
                    flex-sm-row flex-column flex-wrap
                    justify-content-between
                    mb-1 mb-sm-0
                  "
                >
                  <h6>{{status.stage.ar_name}} - {{ status.year.begin }}-{{ status.year.end }} - {{ status.type.ar_title }}</h6>
                  <div>
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="info"
                    class="btn-icon rounded-circle"
                      v-b-modal.model-e-w
                      @click="editStatus(status)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  
                  <small style="margin-right:10px;margin-left:10px;" class="text-muted">{{shortenDate(status.created_at)}}</small></div>
                </div>
                <p>{{status.department.ar_name}} - {{ status.study_type.ar_title }} </p>
                
              </app-timeline-item>
            </app-timeline>
            <div class="demo-inline-spacing">
              <b-button
            @click="stdInfo()"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
            >
            <feather-icon
            icon="Minimize2Icon"
            class="mr-50"
            />
              <span class="align-middle">اغلاق</span> 
            </b-button>
            <b-button
            v-b-modal.model-a-w
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="addStatus(stdinfodetails)"
            >
            <feather-icon
            icon="ArchiveIcon"
            class="mr-50"
            />
              <span class="align-middle">ترحيل الطالب</span> 
            </b-button>
            </div>
          </b-tab>
          <b-tab >
            <template #title>
              <feather-icon icon="HomeIcon" />
              <span>الاوامر الادارية </span>
            </template>
          </b-tab>
        </b-tabs>
        <b-modal
          id="model-e-w"
          ok-title="حفظ التعديلات"
          centered
          size="lg"
          @ok="uSM()"
          @hidden="rSS"
          title="تعديل سيرة الطالب"
        >
              <b-row class="mb-2">
                <b-col  md="4">
                  <b-form-group>
                    <v-select
                      v-model="status.year"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="year"
                      :options="search.years"
                      placeholder="السنة الدراسية"
                    />
                  </b-form-group>
                </b-col>
                
                <b-col  md="4">
                  <b-form-group>
                    <v-select
                      v-model="status.department"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="search.departments"
                      placeholder="القسم العلمي"
                    />
                  </b-form-group>
                </b-col>
                
                <b-col  md="4">
                  <b-form-group>
                    <v-select
                      v-model="status.stage"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="search.stages"
                      placeholder="المرحلة الدراسية"
                    />
                  </b-form-group>
                </b-col>
                
                <b-col  md="4">
                  <b-form-group>
                    <v-select
                      v-model="status.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="ar_title"
                      :options="search.types"
                      placeholder="نوع الدراسة"
                    />
                  </b-form-group>
                </b-col>
              <b-col  md="4">
              <b-form-group>
                <v-select
                  v-model="status.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="ar_title"
                  :options="search.statuses"
                  placeholder="حالة الطالب في هذه السنة"
                />
              </b-form-group>
              </b-col>
                  </b-row>
        </b-modal>
        <b-modal
          id="model-a-w"
          ok-title="حفظ السيرة الجديدة"
          centered
          @ok="aSM()"
          @hidden="rSS"
          size="lg"
          title="ترحيل الطالب"
        >
        <b-row class="mb-2">
          <b-col  md="4">
            <b-form-group>
              <v-select
                v-model="status.year"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="year"
                :options="search.years"
                placeholder="السنة الدراسية"
              />
            </b-form-group>
          </b-col>
          
          <b-col  md="4">
            <b-form-group>
              <v-select
                v-model="status.department"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="search.departments"
                placeholder="القسم العلمي"
              />
            </b-form-group>
          </b-col>
          
          <b-col  md="4">
            <b-form-group>
              <v-select
                v-model="status.stage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="search.stages"
                placeholder="المرحلة الدراسية"
              />
            </b-form-group>
          </b-col>
          
          <b-col  md="4">
            <b-form-group>
              <v-select
                v-model="status.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="ar_title"
                :options="search.types"
                placeholder="نوع الدراسة"
              />
            </b-form-group>
          </b-col>
        <b-col  md="4">
          <b-form-group>
            <v-select
              v-model="status.old_status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="search.statuses"
              placeholder="الحالة السابقة للطالب"
            />
          </b-form-group>
          
        </b-col>
        <b-col  md="4">
          <b-form-group>
            <v-select
              v-model="status.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="search.statuses"
              placeholder="حالة الطالب في هذه السنة"
            />
          </b-form-group>
        </b-col>
            </b-row>
        </b-modal>
      </b-card>
    </b-col>
  <b-col v-show="stdinfonew" md="12">
    
    <b-card
      title="اضافة معلومات طالب جديد"
      class="text-left"
    >
      <!-- <b-card-text>
      </b-card-text> -->
      <b-row class="mb-2">
        <b-col md="4">
          <b-form-input
            v-model="stdinfodetails.name"
            placeholder="اسم الطالب"
          />
    </b-col>
    <b-col  md="4">
      <b-form-group>
        <v-select
          v-model="stdinfodetails.country"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="country_arNationality"
          :options="search.countries"
          placeholder="الجنسية"
        />
      </b-form-group>
    </b-col>
    <b-col  md="4">
      <b-form-group>
        <v-select
          v-model="stdinfodetails.city"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="search.cities"
          placeholder="المحافظة"
        />
      </b-form-group>
    </b-col>
    <b-col  md="4">
      <b-form-group>
        <v-select
          v-model="stdinfodetails.department"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="search.departments"
          placeholder="القسم العلمي"
        />
      </b-form-group>
    </b-col>
    <b-col  md="4">
      <b-form-group>
        <v-select
          v-model="stdinfodetails.type"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="ar_title"
          :options="search.types"
          placeholder="نوع الدراسة"
        />
      </b-form-group>
    </b-col>
    <b-col  md="4">
      <b-form-group>
        <v-select
          v-model="stdinfodetails.gender"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="ar_title"
          :options="search.genders"
          placeholder="الجنس"
        />
      </b-form-group>
    </b-col>
    <b-col md="4">
      <b-form-input
        v-model="stdinfodetails.birthday"
        placeholder="التولد"
      />
</b-col>
    <b-col  md="4">
      <b-form-group>
        <v-select
          v-model="stdinfodetails.branch"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="search.branchs"
          placeholder="الفرع العلمي"
        />
      </b-form-group>
    </b-col>
    <b-col  md="4">
      <b-form-group>
        <v-select
          v-model="stdinfodetails.graduationyear"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="year"
          :options="search.years"
          placeholder="سنة التخرج"
        />
      </b-form-group>
    </b-col>
    
    <b-col md="4">
      <b-form-input
        v-model="stdinfodetails.average"
        placeholder="المعدل"
      />
</b-col>
<b-col  md="4">
  <b-form-group>
    <v-select
      v-model="stdinfodetails.accepttype"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="title"
      :options="search.accepttypes"
      placeholder="نوع النافذة"
    />
  </b-form-group>
</b-col>
<b-col  md="4">
  <b-form-group>
    <v-select
      v-model="stdinfodetails.window"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="title"
      :options="search.windows"
      placeholder="رقم النافذة"
    />
  </b-form-group>
</b-col>
    <b-col  md="4">
      <b-form-group>
        <b-form-textarea
            id="input-invalid1"
            placeholder="الملاحظات"
            v-model="stdinfodetails.note"
              rows="3"
          />
      </b-form-group>
    </b-col>
      </b-row>
    </b-card><b-card
    title="معلومات السنة الدراسية"
    class="text-left"
  >
    <!-- <b-card-text>
    </b-card-text> -->
    <b-row class="mb-2">
  <b-col  md="4">
    <b-form-group>
      <v-select
        v-model="stdinfodetails.year"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="year"
        :options="search.years"
        placeholder="السنة الدراسية"
      />
    </b-form-group>
  </b-col>
  
  <b-col  md="4">
    <b-form-group>
      <v-select
        v-model="stdinfodetails.department"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="title"
        :options="search.departments"
        placeholder="القسم العلمي"
      />
    </b-form-group>
  </b-col>
  
  <b-col  md="4">
    <b-form-group>
      <v-select
        v-model="stdinfodetails.stage"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="title"
        :options="search.stages"
        placeholder="المرحلة الدراسية"
      />
    </b-form-group>
  </b-col>
  
  <b-col  md="4">
    <b-form-group>
      <v-select
        v-model="stdinfodetails.type"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="ar_title"
        :options="search.types"
        placeholder="نوع الدراسة"
      />
    </b-form-group>
  </b-col>
<b-col  md="4">
<b-form-group>
  <v-select
    v-model="stdinfodetails.status"
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    label="ar_title"
    :options="search.statuses"
    placeholder="حالة الطالب في هذه السنة"
  />
</b-form-group>
</b-col>
    </b-row>
    <div class="demo-inline-spacing">
      <b-button
    @click="stdInfonew()"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="primary"
    >
    <feather-icon
    icon="Minimize2Icon"
    class="mr-50"
    />
      <span class="align-middle">اغلاق</span> 
    </b-button>
    <b-button
    @click="aNM()"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
    >
    <feather-icon
    icon="ArchiveIcon"
    class="mr-50"
    />
      <span class="align-middle">اضافة المعلومات</span> 
    </b-button>
    </div>
  </b-card>
</b-col>
  <b-card-code
      title="معلومات الطلبة"
      no-body
    >
    <b-row style="margin: 10px">
        <b-col md="4">
                <b-form-input
                  v-model="search.title"
                  placeholder="بحث عن طريق اسم الطالب"
                />
          </b-col>
          <b-col  md="4">
            <b-form-group>
              <v-select
                v-model="search.year_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="year"
                :options="search.years"
                placeholder="اختر السنة الدراسية"
              />
            </b-form-group>
          </b-col>
          <b-col  md="4">
            <b-form-group>
              <v-select
                v-model="search.department_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="search.departments"
                placeholder="اختر القسم العلمي"
              />
            </b-form-group>
          </b-col>
          <b-col  md="4">
            <b-form-group>
              <v-select
                v-model="search.stage_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="search.stages"
                placeholder="اختر المرحلة الدراسية"
              />
            </b-form-group>
          </b-col>
          <b-col  md="4">
            <b-form-group>
              <v-select
                v-model="search.type_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="ar_title"
                :options="search.types"
                placeholder="اختر نوع الدراسة"
              />
            </b-form-group>
          </b-col>
          <b-col  md="4">
            <b-form-group>
              <v-select
                v-model="search.status_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="ar_title"
                :options="search.statuses"
                placeholder="اختر حالة الطالب"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
              <b-button
            @click="stdInfonew()"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
            >
            <feather-icon
            icon="Minimize2Icon"
            class="mr-50"
            />
              <span class="align-middle">اضافة طالب جديد</span> 
            </b-button>
          </b-col>
      </b-row>
      <div>
        <b-table
          :items="items"
          :fields="fields"
          striped
          responsive
          class="mb-0"
        >
          <template #cell(show_details)="row">
            <b-form-checkbox
              v-model="row.detailsShowing"
              
              @change="row.toggleDetails"
            >
              {{ row.detailsShowing ? 'Hide' : 'Show' }}
            </b-form-checkbox>
          </template>
  
          <!-- full detail on click -->
          <template #row-details="row">
            <b-card>
              <b-row class="mb-2">
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>اسم الطالب : </strong>{{ row.item.info_name.name }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>الجنس : </strong>{{ row.item.info_name.gender.ar_title }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>الجنسية : </strong>{{ row.item.info_name.country.country_arNationality }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>المدينة : </strong>{{ row.item.info_name.city.title }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>الاختصاص : </strong>{{ row.item.info_name.branch.title }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>سنة التخرج : </strong>{{ row.item.info_name.graguated.begin }} - {{ row.item.info_name.graguated.end }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>رقم النافذة : </strong>{{ row.item.info_name.admission.title }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>النافذة : </strong>{{ row.item.info_name.accept.title }}
                </b-col>
                <b-col v-if="row.item.info_name.user!=null"
                  md="4"
                  class="mb-1"
                >
                  <strong>الايميل الجامعي : </strong>{{ row.item.info_name.user.email }}
                </b-col>
              </b-row>
  
              <b-button style="margin-left: 10px;"
                size="sm"
                variant="outline-primary"
                @click="stdInfo(row.item.stdinf_id)"
              >
                تعديل المعلومات الشخصية
              </b-button>
              <b-button style="margin-left: 10px;"
                size="sm"
                variant="outline-secondary"
                @click="row.toggleDetails"
              >
                Hide Details
              </b-button>
            </b-card>
          </template>
  
          <template #cell(avatar)="data">
            <b-avatar :src="data.value" />
          </template>
  
          <template #cell(year)="data">
            <p>
                {{data.item.year.begin}} - {{data.item.year.end}}
            </p>
              
          </template>
        </b-table>
        <b-row style="padding: 20px;">
            <b-col md="11">
                <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @change="check()"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
            <b-col md="1">
                <b-form-select
                id="perPageSelect"
                v-model="perPage"
                @change="check()"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-col>
        </b-row>
      </div>
  
    </b-card-code>
  </div>
    
  </template>
  
  <script>
  import Ripple from "vue-ripple-directive";
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
  import {
    BTable, BFormCheckbox,BFormTextarea,
  BTabs,BFormRow,BForm,
  BTab,BFormInput,BPagination,BFormSelect, BFormGroup, BButton, BCard, BRow, BCol, BAvatar, BBadge,BModal,
  } from 'bootstrap-vue'
import vSelect from "vue-select";
  export default {
    components: {
      BCardCode,BFormRow,
      BTable,Ripple,BForm,
  BTabs,AppTimeline,
  BTab,AppTimelineItem,BModal,
      BButton,BPagination,
      BFormCheckbox,BFormTextarea,
      BCard,BFormGroup,
      BRow,vSelect,BFormInput,
      BCol,BFormSelect,
      BBadge,
      BAvatar,
    },
    directives: {
    Ripple,
  },
    data() {
      return {
        totalRows: 1,
        currentPage: 0,
        perPage: 3,
        stdinfo:false,
        stdinfonew:false,
        stdinfodetails:{
          name:null,
          stdinf_id:null,
          country:null,
          country_code:null,
          city:null,
          city_id:null,
          department:null,
          department_id:null,
          type:null,
          type_id:null,
          gender:null,
          gender_id:null,
          birthday:null,
          branch:null,
          branch_id:null,
          year:null,
          year_id:null,
          average:null,
          accepttype:null,
          accepttype_id:null,
          window:null,
          window_id:null,
          note:null,
          graduationyear:null,
          graduationyear_id:null,
          status:null
        },
        statuses:[],
        status:{
          id:null,
          stdinf_id:null,
          year:null,
          year_id:null,
          stage:null,
          stage_id:null,
          department:null,
          department_id:null,
          type:null,
          type_id:null,
          status:null,
          status_id:null,
          old_status:null
        },
        pageOptions: [3, 5, 10, 100],
        fields: [
            { key: 'show_details', label: 'عرض البيانات' }, 
            { key: 'avatar', label: 'الصورة' }, 
            { key: 'id', label: 'رقم الطالب' }, 
            { key: 'info_name.name', label: 'اسم الطالب' },
            { key: 'year', label: 'السنة الدراسية' },
            { key: 'department.ar_name', label: 'القسم العلمي' },
            { key: 'stage.ar_name', label: 'المرحلة الدراسية' },
            { key: 'study_type.ar_title', label: 'نوع الدراسة' },
            { key: 'type.ar_title', label: 'حالة الطالب' },
        ],
        items: [],
        search:{
            title:"",
            departments:[],
            years:[],
            stages:[],
            types:[],
            statuses:[],
            countries:[],
            cities:[],
            genders:[],
            branchs:[],
            windows:[],
            accepttypes:[],
            department_id:null,
            year_id:null,
            stage_id:null,
            type_id:null,
            status_id:null,
        },
        department_id:null,
        year_id:null,
        stage_id:null,
        type_id:null,
        status_id:null,
      }
    },
    watch: {
      "search.title": function (val) {
      if (val != null && val != "") {
        this.gS();
      }
    },"search.department_id": function (val) {
      if (val != null && val != "") {
        this.gSM2(),this.gS();
      }
    },"search.year_id": function (val) {
      if (val != null && val != "") {
        this.gS();
      }
    },"search.stage_id": function (val) {
      if (val != null && val != "") {
        this.gS();
      }
    },"search.type_id": function (val) {
      if (val != null && val != "") {
        this.gS();
      }
    },"search.status_id": function (val) {
      if (val != null && val != "") {
        this.gS();
      }
    },"stdinfodetails.department": function (val) {
      if (val != null && val != "") {
        this.gSM11();
      }
    },"status.department": function (val) {
      if (val != null && val != "") {
        this.gSM12();
      }
    },
    },
    mounted() {
      this.gS(),this.gSM(),this.gSM1(),this.gSM3(),this.gSM4()
      setTimeout(() => this.gSM5(),
        this.gSM6(),
        this.gSM7(),
        this.gSM8(),
        this.gSM9(),
        this.gSM10(), 3000);
    },
    methods: {
        async gSM() {
      await this.axios
        .get(
          `since/deparment?take=100&skip=0&select=true`
        )
        .then((res) => {
          this.search.departments = [];
          this.search.departments = res.data.items;
        })
    },
        async gSM1() {
      await this.axios
        .get(
          `years?take=100&skip=0&select=true`
        )
        .then((res) => {
          this.search.years = [];
          this.search.years = res.data.items;
        })
    },
        async gSM2() {
            this.search.stages = [];
            this.stage_id == "";
      if (isNaN(this.department_id)) {
        var department_id = this.department_id["id"];
      }
      await this.axios
        .get(
          `stage/clint/department/${department_id}?select=true`
        )
        .then((res) => {
          this.search.stages = [];
          this.search.stages = res.data.items;
        })
    },
    async gSM3() {
      await this.axios
        .get(`typesofstudy`)
        .then((res) => {
          this.search.types = res.data.items;
        })
    },
    async gSM4() {
      await this.axios
        .get(`typeofstatus`)
        .then((res) => {
          this.search.statuses = res.data.items;
        })
    },
    async gSM5() {
      await this.axios
        .get(`country?take=1000&skip=0`)
        .then((res) => {
          this.search.countries = res.data.items;
        })
    },
    async gSM6() {
      await this.axios
        .get(`cities?take=100&skip=0`)
        .then((res) => {
          this.search.cities = res.data.items;
        })
    },
    async gSM7() {
      await this.axios
        .get(`genders`)
        .then((res) => {
          this.search.genders = res.data.items;
        })
    },
    async gSM8() {
      await this.axios
        .get(`branchs`)
        .then((res) => {
          this.search.branchs = res.data.items;
        })
    },
    async gSM9() {
      await this.axios
        .get(`addwindows`)
        .then((res) => {
          this.search.windows = res.data.items;
        })
    },
    async gSM10() {
      await this.axios
        .get(`accepttype`)
        .then((res) => {
          this.search.accepttypes = res.data.items;
        })
    },
    async gSM11() {
            this.search.stages = [];
            this.stdinfodetails.stage_id == "";
      if (isNaN(this.stdinfodetails.department)) {
        var department_id = this.stdinfodetails.department["id"];
      }
      await this.axios
        .get(
          `stage/clint/department/${department_id}?select=true`
        )
        .then((res) => {
          this.search.stages = [];
          this.search.stages = res.data.items;
        })
    },
    async gSM12() {
            this.search.stages = [];
            var department_id=typeof this.status.department === 'object' && this.status.department !== null? this.status.department.id:this.status.department_id
      await this.axios
        .get(
          `stage/clint/department/${department_id}?select=true`
        )
        .then((res) => {
          this.search.stages = [];
          this.search.stages = res.data.items;
        })
    },
    async aNM() {
      var country_code,city_id,department_id,type_id,gender_id,branch_id,graduationyear_id,accepttype_id,window_id,year_id,stage_id,statustype_id;
      if (isNaN(this.stdinfodetails.country)) {
        country_code = this.stdinfodetails.country["country_code"];
      }
      if (isNaN(this.stdinfodetails.city)) {
        city_id = this.stdinfodetails.city["id"];
      }
      if (isNaN(this.stdinfodetails.department)) {
        department_id = this.stdinfodetails.department["id"];
      }
      if (isNaN(this.stdinfodetails.type)) {
        type_id = this.stdinfodetails.type["id"];
      }
      if (isNaN(this.stdinfodetails.gender)) {
        gender_id = this.stdinfodetails.gender["id"];
      }
      if (isNaN(this.stdinfodetails.branch)) {
        branch_id = this.stdinfodetails.branch["id"];
      }
      if (isNaN(this.stdinfodetails.graduationyear)) {
        graduationyear_id = this.stdinfodetails.graduationyear["id"];
      }
      if (isNaN(this.stdinfodetails.accepttype)) {
        accepttype_id = this.stdinfodetails.accepttype["id"];
      }
      if (isNaN(this.stdinfodetails.window)) {
        window_id = this.stdinfodetails.window["id"];
      }
      if (isNaN(this.stdinfodetails.year)) {
        year_id = this.stdinfodetails.year["id"];
      }
      if (isNaN(this.stdinfodetails.stage)) {
        stage_id = this.stdinfodetails.stage["id"];
      }
      if (isNaN(this.stdinfodetails.status)) {
        statustype_id = this.stdinfodetails.status["id"];
      }
      let data = new FormData();
      data.append("name", this.stdinfodetails.name);
      data.append("country_code", country_code);
      data.append("city_id", city_id);
      data.append("department_id", department_id);
      data.append("type_id", type_id);
      data.append("gender_id", gender_id);
      data.append("birthday", this.stdinfodetails.birthday);
      data.append("branch_id", branch_id);
      data.append("graduationyear_id", graduationyear_id);
      data.append("average", this.stdinfodetails.average);
      data.append("accepttype_id", accepttype_id);
      data.append("window_id", window_id);
      data.append("note", this.stdinfodetails.note);
      data.append("year_id", year_id);
      data.append("stage_id", stage_id);
      data.append("statustype_id", statustype_id);
      await this.axios
        .post(`stdinfo`, data)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            
            this.gS();
          }
          // this.precentage = 0;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
        this.resetStdD()
    },
    async uNM() {
      var country_code,city_id,department_id,type_id,gender_id,branch_id,graduationyear_id,accepttype_id,window_id;
      country_code=typeof this.stdinfodetails.year === 'object' && this.stdinfodetails.year !== null? this.stdinfodetails.country.country_code:this.stdinfodetails.country_code
      city_id=typeof this.stdinfodetails.city === 'object' && this.stdinfodetails.city !== null? this.stdinfodetails.city.id:this.stdinfodetails.city_id
      department_id=typeof this.stdinfodetails.department === 'object' && this.stdinfodetails.department !== null? this.stdinfodetails.department.id:this.stdinfodetails.department_id
      type_id=typeof this.stdinfodetails.type === 'object' && this.stdinfodetails.type !== null? this.stdinfodetails.type.id:this.stdinfodetails.type_id
      gender_id=typeof this.stdinfodetails.gender === 'object' && this.stdinfodetails.gender !== null? this.stdinfodetails.gender.id:this.stdinfodetails.gender_id
      branch_id=typeof this.stdinfodetails.branch === 'object' && this.stdinfodetails.branch !== null? this.stdinfodetails.branch.id:this.stdinfodetails.branch_id
      graduationyear_id=typeof this.stdinfodetails.graduationyear === 'object' && this.stdinfodetails.graduationyear !== null? this.stdinfodetails.graduationyear.id:this.stdinfodetails.graduationyear_id
      accepttype_id=typeof this.stdinfodetails.accepttype === 'object' && this.stdinfodetails.accepttype !== null? this.stdinfodetails.country.accepttype:this.stdinfodetails.accepttype_id
      window_id=typeof this.stdinfodetails.window === 'object' && this.stdinfodetails.window !== null? this.stdinfodetails.window.id:this.stdinfodetails.window_id
      let data = new FormData();
      data.append("name", this.stdinfodetails.name);
      data.append("country_code", country_code);
      data.append("city_id", city_id);
      data.append("department_id", department_id);
      data.append("type_id", type_id);
      data.append("gender_id", gender_id);
      data.append("birthday", this.stdinfodetails.birthday);
      data.append("branch_id", branch_id);
      data.append("graduationyear_id", graduationyear_id);
      data.append("average", this.stdinfodetails.average);
      data.append("accepttype_id", accepttype_id);
      data.append("window_id", window_id);
      data.append("note", this.stdinfodetails.note);
      await this.axios
        .post(`stdinfo/${this.stdinfodetails.stdinf_id}`, data)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            
            this.gS();
          }
          // this.precentage = 0;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
        this.resetStdD()
        this.stdInfo=false
        this.gS()
    },
    async uSM() {
      var i,year_id,department_id,stage_id,statustype_id,type_id;
      i=this.status.id;
      year_id=typeof this.status.year === 'object' && this.status.year !== null? this.status.year.id:this.status.year_id
      department_id=typeof this.status.department === 'object' && this.status.department !== null? this.status.department.id:this.status.department_id
      stage_id=typeof this.status.stage === 'object' && this.status.stage !== null? this.status.stage.id:this.status.stage_id
      statustype_id=typeof this.status.status === 'object' && this.status.status !== null? this.status.status.id:this.status.status_id
      type_id=typeof this.status.type === 'object' && this.status.type !== null? this.status.type.id:this.status.type_id
      let data = new FormData();
      data.append("i", i);
      data.append("year_id", year_id);
      data.append("department_id", department_id);
      data.append("stage_id", stage_id);
      data.append("statustype_id", statustype_id);
      data.append("type_id", type_id);
      await this.axios
        .post(`statusstd/${i}`, data)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gSoS(res.data.stdinf_id);
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
        this.rSS()
    },
    async aSM() {
      var old_status,year_id,department_id,stage_id,statustype_id,type_id,stdinf_id;
      stdinf_id = this.status.stdinf_id
      year_id=typeof this.status.year === 'object' && this.status.year !== null? this.status.year.id:this.status.year_id
      department_id=typeof this.status.department === 'object' && this.status.department !== null? this.status.department.id:this.status.department_id
      stage_id=typeof this.status.stage === 'object' && this.status.stage !== null? this.status.stage.id:this.status.stage_id
      statustype_id=typeof this.status.status === 'object' && this.status.status !== null? this.status.status.id:this.status.status_id
      old_status= this.status.old_status.id
      type_id=typeof this.status.type === 'object' && this.status.type !== null? this.status.type.id:this.status.type_id
      let data = new FormData();
      data.append("stdinf_id", stdinf_id);
      data.append("year_id", year_id);
      data.append("department_id", department_id);
      data.append("stage_id", stage_id);
      data.append("statustype_id", statustype_id);
      data.append("type_id", type_id);
      data.append("old_status", old_status);
      await this.axios
        .post(`statusstd`, data)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gSoS(res.data.stdinf_id);
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
        this.rSS()
    },
    async gSoS(id){
      await this.axios
          .get(`statusstd/${id}`)
          .then((res) => {
            console.log(res)
            this.statuses=[]
            this.statuses = res.data;
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "danger",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
    },
        async gS() {
          var department,stage,type,status,year;
          if (isNaN(this.search.department_id)) {
              department = this.search.department_id["id"];
            }if (isNaN(this.search.stage_id)) {
              stage = this.search.stage_id["id"];
            }if (isNaN(this.search.type_id)) {
              type = this.search.type_id["id"];
            }if (isNaN(this.search.status_id)) {
              status = this.search.status_id["id"];
            }if (isNaN(this.search.year_id)) {
              year = this.search.year_id["id"];
            }
        await this.axios
          .get(`statusstd?take=${this.perPage}&skip=${this.currentPage - 1}
          &title=${this.search.title}&department=${department}&stage=${stage}
          &type=${type}&status=${status}&year=${year}`)
          .then((res) => {
            this.items = [];
            this.totalRows = res.data.totalCount;
            this.items = res.data.items;
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "danger",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
      },
    makeToast(variant = null, title = null, body = null) {
        this.$bvToast.toast(body, {
          title: title,
          variant,
          solid: false,
        });
      },
    errorToast() {
        this.makeToast(
          "danger",
          "update failed",
          "some thing want wrong, i`m sorry :("
        );
      },
      check() {
        setTimeout(() => this.gS(), 1000);
      },
      async stdInfonew(){
        this.stdinfonew = !this.stdinfonew;
        if(this.stdinfo==true){
          this.stdinfo=false
        }
        this.resetStdD()
      },

      async stdInfo(id){
      this.stdinfo = !this.stdinfo;
      if(this.stdinfonew==true){
          this.stdinfonew=false
          this.resetStdD()
        }
        if(this.stdinfo==true){
        await this.axios
          .get(`stdinfo/${id}`)
          .then((res) => {
            this.editStdD(res.data.item);
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "danger",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });}
    },
    editStdD(item){
          this.stdinfodetails.stdinf_id=item.id,
          this.stdinfodetails.name=item.name,
          this.stdinfodetails.country=item.country.country_arNationality,
          this.stdinfodetails.country_code=item.country.country_code,
          this.stdinfodetails.city=item.city.title,
          this.stdinfodetails.city_id=item.city.id,
          this.stdinfodetails.department=item.department.ar_name,
          this.stdinfodetails.department_id=item.department.id,
          this.stdinfodetails.type=item.type.ar_title,
          this.stdinfodetails.type_id=item.type.id,
          this.stdinfodetails.gender=item.gender.ar_title,
          this.stdinfodetails.gender_id=item.gender.id,
          this.stdinfodetails.birthday=item.birthday,
          this.stdinfodetails.branch=item.branch.title,
          this.stdinfodetails.branch_id=item.branch.id,
          this.stdinfodetails.graduationyear_id=item.graguated.id,
          this.stdinfodetails.graduationyear=item.graguated.end+'-'+item.graguated.begin,
          this.stdinfodetails.average=item.average,
          this.stdinfodetails.accepttype=item.accept.title,
          this.stdinfodetails.accepttype_id=item.accept.id,
          this.stdinfodetails.window=item.admission.title,
          this.stdinfodetails.window_id=item.admission.id,
          this.stdinfodetails.note=item.note,
          this.statuses = item.status
    },
    editStatus(item){
          this.status.id=item.id,
          this.status.stdinf_id=item.stdinf_id,
          this.status.year_id=item.year.id,
          this.status.year=item.year.end+'-'+item.year.begin,
          this.status.stage_id=item.stage.id,
          this.status.stage=item.stage.ar_name,
          this.status.department=item.department.ar_name,
          this.status.department_id=item.department.id,
          this.status.type=item.study_type.ar_title,
          this.status.type_id=item.study_type.id,
          this.status.status=item.type.ar_title,
          this.status.status_id=item.type.id
    },
    addStatus(item){
      console.log(item)
          this.status.stdinf_id=item.stdinf_id
    },
    rSS(){
          this.status.id=null,
          this.status.stdinf_id=null
          this.status.year_id=null,
          this.status.year=null,
          this.status.stage_id=null,
          this.status.stage=null,
          this.status.department=null,
          this.status.department_id=null,
          this.status.type=null,
          this.status.type_id=null,
          this.status.status=null,
          this.status.status_id=null,
          this.status.old_status=null
    },
    resetStdD(){
          this.stdinfodetails.name=null,
          this.stdinfodetails.country=null,
          this.stdinfodetails.country_code=null,
          this.stdinfodetails.city=null,
          this.stdinfodetails.city_id=null,
          this.stdinfodetails.department=null,
          this.stdinfodetails.department_id=null,
          this.stdinfodetails.type=null,
          this.stdinfodetails.type_id=null,
          this.stdinfodetails.gender=null,
          this.stdinfodetails.gender_id=null,
          this.stdinfodetails.birthday=null,
          this.stdinfodetails.branch=null,
          this.stdinfodetails.branch_id=null,
          this.stdinfodetails.year_id=null,
          this.stdinfodetails.year=null,
          this.stdinfodetails.average=null,
          this.stdinfodetails.accepttype=null,
          this.stdinfodetails.accepttype_id=null,
          this.stdinfodetails.window=null,
          this.stdinfodetails.window_id=null,
          this.stdinfodetails.note=null,
          this.statuses = [],
          this.search.stages = [],
          this.stdinfodetails.year=null,
          this.stdinfodetails.stage=null,
          this.stdinfodetails.status=null
    },
    shortenDate(date) {
      let newDate = date.split("");
      newDate = newDate.slice(0, 10);
      newDate = newDate.join("");
      return newDate;
    },
    },
  }
  </script>
  